var site = site || {};
(function($) {
  Drupal.behaviors.jpGnavAccountFormatterV1 = {
    attached: false,
    attach: function(context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-gnav-account-formatter', context);
      var $newMemberLink = $('.js-new-member-link', $template);
      var $signinForm = $('.js-gnav-account-content-container-forms-signin-container-form', $template);
      if ($signinForm.length > 0) {
        $newMemberLink.insertAfter($signinForm).removeClass('hidden');
      }
    }
  };
})(jQuery);
